import React from 'react'
import {
  Container,
  Col,
  Row,
  Button,
} from 'reactstrap'
import * as R from 'ramda'

import Layout from '../components/layout'
import { graphql } from 'gatsby';
import { css } from 'emotion'
import { Link } from 'gatsby'

import bgBanner1 from '../images/main_banner.jpg'
import interviewImage from '../images/banner3/interview.png'
import matchmakingImage from '../images/banner3/matchmaking.png'
import usmcaImage from '../images/banner3/usmca.png'
import poolImage from '../images/banner3/pool.png'
import devsImage from '../images/banner3/devs.png'
import densityImage from '../images/banner3/density.png'
import cultureImage from '../images/banner3/culture.png'
import contractImage from '../images/banner3/contract.png'
import howDoWeFindImage from '../images/how-do-we-find.png'

const dataToTechnologies = R.pipe(
  R.view(R.lensPath(['technologies', 'edges'])),
  R.map(R.view(R.lensPath(['node', 'publicURL'])))
)

const siteMeta = {
  subtitle: 'Home',
  type: 'website'
}

const Index = ({ data }) => (
  <div>
    <Layout siteMeta={siteMeta}>
      <div css={css`
          background-image: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),url(${bgBanner1});
          background-position: center;
          background-size: cover;
          width: 100%;
          height: 350px;
          @media (min-width: 768px) {
            height: 400px;
          }
          @media (min-width: 992px) {
            height: 450px;
          }
          @media (min-width: 1440px) {
            height: 500px;
          }
        `}
      >
        <Container className="h-100">
          <Row className="h-100">
            <Col className="text-white h-100 d-flex align-items-center justify-content-start">
              <div>
                <h1 className="font-weight-bold">Need A Talented Software Developer...</h1>
                <h1 className="font-weight-bold">Or A Whole Team of Highly-Vetted Engineers?</h1>
                <h4 className="font-weight-light d-inline-flex">
                  Use our pool of 200+ senior developers<br/>
                  who are in the top 2% of talent and have a 100% success rate! 
                </h4>
                <div>
                  <a className=" ml-2 text-white font-weight-bold" css={css`text-decoration: underline;`} href="mailto:info@densitylabs.io">
                    Assemble Your Team Now!
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {banner2()}
        <Row className="my-2 mt-5 ">
          {resourcesForHire()}
        </Row>
        {banner3()}
        <Row className="my-2 mt-4">
          {ourTechnologies(data)}
        </Row>
      </Container>
      <Container className="text-center">
        <h2 className="py-3">How Do We Find The Top 2% of Developers?</h2>
        <Row>
          <Col md={6}>
            <Col className="pt-5"
                css={css`
                  border-bottom: 2px solid black;
                  font-heigth: 20px;
                  width: 200px;
                  margin: 0 auto;
                  line-height: 22px;
                `}
            >
              <p className="font-weight-bold" 
                css={css`
                  font-size: 24px;
                `}
              >STEP #1</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 28px;
                `}
              >100%</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 24px;
                `}
              >CANDIDATES</p>
            </Col>
            <Col className="pt-5"
                css={css`
                  border-bottom: 2px solid black;
                  font-heigth: 20px;
                  width: 200px;
                  margin: 0 auto;
                  line-height: 22px;
                `}
            >
              <p className="font-weight-bold" 
                css={css`
                  font-size: 24px;
                `}
              >STEP #2</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 28px;
                `}
              >91%</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 24px;
                `}
              >CANDIDATES</p>
            </Col>
            <Col className="pt-5"
                css={css`
                  border-bottom: 2px solid black;
                  font-heigth: 20px;
                  width: 200px;
                  margin: 0 auto;
                  line-height: 22px;
                `}
            >
              <p className="font-weight-bold" 
                css={css`
                  font-size: 24px;
                `}
              >STEP #3</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 28px;
                `}
              >73%</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 24px;
                `}
              >CANDIDATES</p>
            </Col>
            <Col className="pt-5"
                css={css`
                  border-bottom: 2px solid black;
                  font-heigth: 20px;
                  width: 200px;
                  margin: 0 auto;
                  line-height: 22px;
                `}
            >
              <p className="font-weight-bold" 
                css={css`
                  font-size: 24px;
                `}
              >STEP #4</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 28px;
                `}
              >27%</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 24px;
                `}
              >CANDIDATES</p>
            </Col>
            <Col className="pt-5"
                css={css`
                  font-heigth: 20px;
                  width: 200px;
                  margin: 0 auto;
                  line-height: 22px;
                `}
            >
              <p className="font-weight-bold" 
                css={css`
                  font-size: 24px;
                `}
              >ACEPTED<br/>FOR CLIENT<br/>INTERVIEW</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 24px;
                `}
              >CANDIDATES</p>
              <p className="font-weight-bold"
                css={css`
                  font-size: 28px;
                `}
              >= 2%</p>
            </Col>
          </Col>
          <Col md={6} className=""
            css={css`
                background-image: url(${howDoWeFindImage});
                background-size: contain;
                width: 100%;
                height: 900px;
                background-repeat: no-repeat;
            `}>

          </Col>
        </Row>
      </Container>

      <div className="text-white p-5 text-center" css={css`background-color: #dc3545;`}>
          <h1 className="text-white font-weight-bold mb-4">Let's Find The Perfect Developers For Your Project!</h1>
          <h3>
            In just minutes, we can start finding the talented engineers you<br/>
            need to make your next project come to life
          </h3>
          <Link to="/contact-us">
            <Button color="dark" size="lg">
            Schedule A Free 30-Minute Consultation Now!
            </Button>
          </Link>
      </div>
    </Layout>
  </div>
)

export const pageQuery = graphql`
  query IndexQuery {
    technologies: allFile(
      filter: { relativeDirectory: { eq: "technologies" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`
export default Index

const resourcesForHire = () => (
  <Col className="py-3 text-center" 
    css={css`
      background:#DADFE3;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
    `}
  >
    <h3 className="font-weight-bold mb-4 ">We Stand By The Quality of Our Software Engineers!</h3>
    <p>We provide you with a 3-month risk-free trial with a full refund for new clients</p>
    <h4 className="my-4" 
      css={css`
        & b { color: #dc3545; }
      `}
    > 
      We screen the best 2% <b className = "px-2">|</b> 
      100% success rate the last 2 years <b className="px-2">|</b> 
      Easy Communication in Fluent English
    </h4>
  </Col>
)

const ourTechnologies = (data) => (
  <Col>
    <Row>
      {
        dataToTechnologies(data).map(src => (
          <Col md={2} xs={4} key={src} className="my-3 d-flex align-items-center">
            <img key={src} src={src} alt={src} width="100%" />
          </Col>
        ))
      }
    </Row>
  </Col>
)

const banner2 = () => (
  <Row className="mb-4">
    <Col md={6} className="text-white py-4 mt-4 mr-md-4"
      css={css`
      background: #dc3545;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <h3 className="mb-4 font-weight-bold">If you can dream it, we can code it.</h3>
      <p>
      Stressed about scaling on a budget?<br/>
      Don’t be!<br/>
      Being a visionary is your job...<br/>
      If you can dream it, we can build a team to make it a reality!<br/><br/>
      Density Labs hires only the best software developers, ux/ui designers,
      and quality assurance professionals from Mexico who will streamline your web,
      mobile, and SaaS application development at a fraction of the cost of the traditional hiring model.</p>  
    </Col>
    <Col   className="py-4 mt-4 ml-md-2"
      css={css`
      background: #ffffff;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
    <h3 className="mb-3 font-weight-bold"
      css={css`color:#dc3545; `}
    >Benefits of Nearshore</h3>
    <ul css={css`
      list-style:none;
      padding-left: 0;
      & li{
        padding-top: 5px;
        & b {
          color: #dc3545;
        } 
      }
    `}>
      <li>
        <b>Cost Efficiency & Flexibility</b> - 
        We’re 40-60% less than a direct hire with an equal level of quality & performance. Plus, No long-term commitments
      </li>
      <li> 
        <b>Immediate Team Assembly</b> – 
        We can have a specialized team ready to start your project in 2 weeks and, in some cases, 1 to 3 days!
      </li>
      <li>
        <b>Time Zone Alignment</b> – 
        Our developers work in U.S. time zones, with the same hours, same meetings, and same deadlines.
      </li>
      <li>
        <b>No Payroll Hassles</b> – 
        We handle payments, benefits, and taxes for the developers who do your work.
      </li>
    </ul>
    </Col>
  </Row>
)

const banner3 = () => (
  <Row className="mt-4 mb-4 text-center">
    <h2 className="my-4" css={css`width: 100%;`}>More Reasons You'll Love Working With Developers From Density Labs</h2>
    <Col md={3} className="text-white py-4 mt-4"
      css={css`
      background: #dc3545;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${interviewImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>You can interview, then approve or reject any of our candidates to find the ones you like best</p>
    </Col>
    <Col md={3} className="text-black py-4 mt-4"
      css={css`
      background: #fff;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${matchmakingImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>Our matchmaking process aligns you with the best-fit candidates for your roles</p>
    </Col>
    <Col md={3} className="text-black py-4 mt-4"
      css={css`
      background: #DADFE3;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${usmcaImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>We strongly adhere to the USMCA IP protections, which keep you safe from  copyright and trademark infringements</p>
    </Col>
    <Col md={3} className="text-white py-4 mt-4"
      css={css`
      background: #000;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${poolImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>Our pool of engineers will always sign NDA agreements or other privacy documents you desire</p>
    </Col>
    <Col md={3} className="text-white py-4"
      css={css`
      background: #000;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${devsImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>Small, medium, or large, our developers can help companies of all sizes and start adding value on day 1!</p>
    </Col>
    <Col md={3} className="text-black py-4"
      css={css`
      background: #DADFE3;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${densityImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>Your Density Labs developers speak fluent English and will understand the nuances of your team’s workflow</p>
    </Col>
    <Col md={3} className="text-black py-4"
      css={css`
      background: #fff;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${cultureImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>Cultural similarities between the U.S. and Mexico make for healthy and productive working relationships with our engineers and your employees.</p>
    </Col>
    <Col md={3} className="text-white py-4"
      css={css`
      background: #dc3545;
      box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
      &:hover{ box-shadow: 0 0 !important;}
      `}
    >
      <div css={css`
          background-image: url(${contractImage});
          background-size: cover;
          width: 100%;
          height: 300px;
          @media (min-width: 768px) {
            height: 100px;
          }
          @media (min-width: 992px) {
            height: 150px;
          }
          @media (min-width: 1440px) {
            height: 200px;
          }
      `}></div>
      <p>Our contracts are never long-term and are completely transparent. Our simple billing process makes it easy for you to stay on budget</p>
    </Col>
    <div className="mt-5" 
      css={css`
        text-align: center;
        width: 100%;
      `}
    >
      <a className=" ml-2 text-white font-weight-bold"
        css={css`
        background: #dc3545;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        width: 250px;
        text-decoration: none;
        &:hover{ text-decoration: none; }
      `}
      href="mailto:info@densitylabs.io">
        Let's Chat About Your Needs! 
      </a>
    </div>
  </Row>
)